import React from "react";
import {TextProps} from "@onnit-js/ui/components/text/Text";
import Text from "@onnit-js/ui/components/text";
import { stringify } from "qs";

interface Props extends TextProps {
    receiptId?: string;
    email?: string;
}

const OrderManagementLink: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ children, receiptId, email, ...props }) => {
    const queryString = stringify({ receiptNo: receiptId, email }, { skipNulls: true });
    return (
        <Text as="a" target="_blank" href={`https://www.clkbank.com/${queryString ? `?${queryString}` : ''}`} {...props}>
            {children}
        </Text>
    );
};

export default OrderManagementLink;

import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {Img} from "@onnit-js/ui/components/image";
import Text, {H2} from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import React from "react";
import { UpsellTemplate } from "../../domain/@types";
import clickBankService from "../../domain/ClickBankService";
import PageThemeConfig from "../../domain/PageThemeConfig";
import Badge from "./Badge";

interface Props extends BoxProps {
    isLoading: boolean;
    item: UpsellTemplate['item'];
    upsellId: string;
    sessionId?: string;
}

const UpsellBuyBox: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ isLoading, item, upsellId, sessionId, ...rest }) => {
    const [acceptUrl, declineUrl]: [string | null, string | null] = isLoading ? [null, null] : clickBankService.urlManager.getUpsellDecisionUrls(item.sku, upsellId, sessionId);

    return (
        <Box {...rest}>
            <H2 className="sr-only">Limited Time Offer</H2>
            <Box
                bg={item.bgColor}
                borderRadius="10px"
                position="relative"
                pb={6}
                mx={[4, 4, 0]}
            >
                <Box maxWidth={item.imageMaxWidth} mx="auto" pt={8} pb={4} px={2}>
                    <Img
                        alt={item.description}
                        src={`${item.imageUrl}`}
                        height={640}
                        width={640}
                        lazyLoad={false}
                    />
                </Box>

                <Badge
                    background={PageThemeConfig.creamWhite}
                    textColor={PageThemeConfig.blackish}
                    zIndex={999}
                    fontSize={["32px", "32px", "32px", "32px", "35px"]}
                    badgeSize={["100px", "100px", "100px", "110px"]}
                    top={[6, 8, 8, 6]}
                    right={["12%", "20%", "6%", "13%"]}
                >
                    {item.discountPercent}% off <span className="sr-only">{item.description}</span>
                </Badge>

                <Badge
                    background={PageThemeConfig.creamWhite}
                    textColor={PageThemeConfig.blackish}
                    zIndex={1000}
                    badgeSize={["100px", "100px", "100px", "110px"]}
                    top={["140px", "170px"]}
                    fontSize={["17px", "17px", "17px", "18px", "18px"]}
                    right={["12%", "20%", "6%", "13%"]}
                >
                    Free<br />Shipping
                </Badge>

                <Text textAlign="center" textTransform="uppercase" fontWeight="black" typeStyle="title02" fontStyle="normal">Yes</Text>
                <Box mx="auto" textAlign="center" mt={4}>
                    <Button
                        el="a"
                        href={acceptUrl ?? ""}
                        disabled={!acceptUrl}
                        width={["96%", "96%", "96%", "80%"]}
                    >
                        <>
                            <Text typeStyle="text03" fontSize={["14px", "14px", "15px", 3]} as="span" color={PageThemeConfig.creamWhite} mb={1}>
                                {item.acceptText}
                            </Text>
                            <Text className="small-text" typeStyle="text04" color={PageThemeConfig.creamWhite} as="span" fontWeight="regular" fontStyle="italic">
                                (plus applicable tax)
                            </Text>
                        </>
                    </Button>
                </Box>
            </Box>
            <Text
                typeStyle="text03"
                fontSize={["14px", "14px", "15px", 3]}
                mt={6}
                mb={8}
                as={declineUrl ? "a" : "p"}
                href={declineUrl ?? ""}
                textAlign="center"
            >
                No, I would like to stick to my original order
            </Text>
        </Box>
    );
};

export default UpsellBuyBox;

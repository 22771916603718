interface ApprovedImage {
    filename: string;
    description: string;
}

const approvedImages = Object.freeze<ApprovedImage[]>([
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080-Chef-3-disclaimer-2.png",
        description: "Social Share (1080x1080)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080-Chef-3-disclaimer.png",
        description: "Social Share (1080x1080)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080-Dorm-2-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080-Gym-2-disclaimer.png",
        description: "Social share (1080x1080)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080-Product-2-disclaimer.png",
        description: "Social Share (1080x1080)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-Dorm-2-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-Gym-2-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-Gym-3-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-Gym-3-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-WFH-3-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
    {
        filename: "AlphaBrain-Brand-2023-OSocial-1080x1920-WFH-4-disclaimer.png",
        description: "Social Share (1080x1920)",
    },
]);

export default approvedImages;

import React, { useEffect, useState } from "react";
import clickBankService from "../../domain/ClickBankService";
import { PageProps } from "../../PageRouter";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import UpsellBuyBox from "./UpsellBuyBox";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import PageThemeConfig from "../../domain/PageThemeConfig";
import Title from "../common/Title";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import FdaStatement from "../common/FdaStatement";

const UpsellPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<PageProps>>> = ({ isLoading, setIsLoading, lastUriPart }) => {
    const [sessionId, setSessionId] = useState<string>();
    const template = lastUriPart ? clickBankService.getUpsellTemplate(lastUriPart) : null;

    useDocumentTitle(template?.documentTitle ?? 'Exclusive Offer');

    // Try to fetch session ID.
    useEffect(() => {
        setIsLoading(true);
        clickBankService.getSessionId(
            ...clickBankService.urlManager.getEncryptedUrlParams()
        )
            .then((sessionId) => setSessionId(sessionId))
            .catch((error) => {
                // Obtaining the session ID to include in accept/decline links is optional as ClickBank will fall back to read from browser cookies if available.
                // However, if it's a 403 Forbidden, then redirect them away from this page as a measure to try to prevent unauthorized upsell purchases.
                console.warn("Failed to get session details.", error);
                if (error?.response?.status === 403) {
                    clickBankService.handleFatalError("Request to get session details failed with a 403 Forbidden response.");
                }
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (!lastUriPart || !template) {
        clickBankService.handleFatalError(`No upsell template defined for upsell ID '${lastUriPart}'.`);
        return null;
    }

    return (
        <>
            <Box maxWidth="1920px" mx="auto">
                <Grid display={["block", "block", "grid", "grid"]} mt={[6, 6, 8]}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "2/ span 5"]} gridRow={1}>
                        <Text as="p" typeStyle="text02" color={PageThemeConfig.darkGrayish} fontWeight="bold" px={[4, 4, 4, 0]}>{template.intro}</Text>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "2 / span 4", "2 / span 5"]} gridRow={2} mt={4}>
                        <Title maxWidth={[400, 400, 600]} px={[4, 4, 4, 0]} as="h1">
                            {template.heading}
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "2 / span 5"]} gridRow={3}>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} mt={[4, 4, 6]} px={[4, 4, 4, 0]}>
                            {template.body}
                        </Text>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "7 / span 6", "8 / span 4"]} gridRow="2 / 7">
                        <UpsellBuyBox
                            pr={[0, 0, 4, 0]}
                            item={template.item}
                            upsellId={lastUriPart}
                            sessionId={sessionId}
                            isLoading={isLoading}
                        />
                    </Box>
                </Grid>
            </Box>
            <Box bg={PageThemeConfig.creamWhite} mt={[6, 6, 8]}>
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "2 / span 10"]} px={[4, 4, 0]}>
                        <FdaStatement p={[0, 0, 4, 0]} />
                    </Box>
                </TwelveColumn>
            </Box>
        </>
    );
};

export default UpsellPage;

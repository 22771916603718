import React, { useEffect, useMemo, useState } from "react";
import PageContainer from "./PageContainer";
import clickBankService from "./domain/ClickBankService";
import AffiliatesPage from "./pages/affiliates/AffiliatesPage";
import AlphaBrainPitchPage from "./pages/pitch/alpha-brain/AlphaBrainPitchPage";
import UpsellPage from "./pages/upsell/UpsellPage";
import OrderConfirmationPage from "./pages/order-confirmation/OrderConfirmationPage";

// A map of the first URI part after "cbk" to each page component.
export const rootSlugsToPageComponents = new Map<string, PageComponent>([
    ['affiliates', AffiliatesPage],
    ['alpha-brain', AlphaBrainPitchPage],
    ['offer', UpsellPage], // Component for all URIs matching /cbk/offer/{offer_id}
    ['order-confirmation', OrderConfirmationPage],
]);

export interface PageProps {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    lastUriPart: string | null;
}

export type PageComponent = React.FC<React.PropsWithChildren<React.PropsWithChildren<PageProps>>>;

const PageRouter: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastUriPart, Component] = useMemo<[string | null, PageComponent | null]>(() => {
        // Remove leading and trailing slashes. A max of three parts is expected currently.
        const uriParts = location.pathname.replace(/\/(.+?)\/?$/, '$1').split('/', 3);
        return [
            uriParts ? uriParts[2] : null,
            uriParts && uriParts[1] ? (rootSlugsToPageComponents.get(uriParts[1]) ?? null) : null,
        ];
    }, [location.pathname]);

    useEffect(() => {
        if (!Component) {
            clickBankService.handleFatalError('URI route does not exist.');
        }
    }, [Component]);

    return (
        <PageContainer>
            {Component && (
                <Component
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    lastUriPart={lastUriPart}
                />
            )}
        </PageContainer>
    );
};

export default PageRouter;

import React, { useState } from "react";
import SelectField from "@onnit-js/ui/components/form/selectfield/SelectField";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import { EmailLeadSourceEnum, EmailListEnum } from "@onnit-js/ui/@types/enums/EmailList";
import EmailListSignUpCustom from "../../../../components/common/EmailListSignUpCustom";
import HubSpotContactPropertyEnum from "../../../../enums/HubSpotContactPropertyEnum";
import {H2} from "@onnit-js/ui/components/text";

interface Props extends BoxProps {
    orderEmail: string;
}

const FITNESS_FOCUSES = Object.freeze([
    'Cardio',
    'Weight Loss',
    'Muscle Gain',
    'General Fitness',
]);

const NewsletterSignup: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ orderEmail, ...boxProps }) => {
    const [fitnessFocus, setFitnessFocus] = useState<string>();

    return (
        <Box {...boxProps}>
            <H2 className="sr-only">Sign up for our newsletter</H2>
            <EmailListSignUpCustom
                emailInitial={orderEmail}
                lists={[EmailListEnum.ONNIT_NEWSLETTER]}
                leadSource={EmailLeadSourceEnum.CLICKBANK}
                hubspotContactProperties={fitnessFocus ? { [HubSpotContactPropertyEnum.FITNESS_FOCUS]: fitnessFocus } : undefined}
                formTop={
                    <SelectField
                        label="What's your fitness focus?"
                        value={fitnessFocus}
                        onChange={(e) => setFitnessFocus(e.target.value)}
                        mb={1}
                    >
                        <option value="">Select...</option>
                        {FITNESS_FOCUSES.map((value) => (
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </SelectField>
                }
                successMessage="Thanks for subscribing! You'll be the first to receive early access to special deals, announcements on new formulas, motivational content, and tips to meet your goals!"
                onSuccess={() => setFitnessFocus(undefined)}
            />
        </Box>
    );
};

export default NewsletterSignup;

import React from "react";
import {Img} from "@onnit-js/ui/components/image";

interface AlphaBrainAuthorityImageProps {
    imgBase: string;
    imgParams: string;
}

const AlphaBrainAuthorityImage: React.FC<React.PropsWithChildren<React.PropsWithChildren<AlphaBrainAuthorityImageProps>>> = ({ imgBase, imgParams }) => (
    <Img
        src={`${imgBase}/authority/107.png${imgParams}&trim=auto`}
        alt="90 count of Alpha Brain"
        width={523}
        height={356}
    />
);

export default AlphaBrainAuthorityImage;

import { useEffect } from "react";

const suffix = 'Onnit';
const useDocumentTitle = (title: string): void => {
    useEffect(() => {
        document.title = [title, suffix].join(' | ');
        return () => {
            document.title = suffix;
        };
    }, []);
};

export default useDocumentTitle;

import React from "react";
import { ClickBankOrder } from "../../domain/@types";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import OrderManagementLink from "../common/OrderManagementLink";
import LoadingSkeletonBox from "../common/LoadingSkeletonBox";

interface Props extends BoxProps {
    cbOrder?: ClickBankOrder;
}

const ShippingInformation: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ cbOrder, ...rest }) => (
    <Box {...rest}>
        <Text as="h2" typeStyle="title02" fontSize={[3, 3, 4, 5]} mb={4}>Shipping Info</Text>
        {cbOrder ? (
            <>
                <Box>
                    <Text as="span">{cbOrder.first_name} {cbOrder.last_name}</Text>
                    <Text mb={4} as="span">{cbOrder.email}</Text>
                    <Text as="span">{cbOrder.address_line1}</Text>
                    {cbOrder.address_line2 && (<Text as="span">{cbOrder.address_line2}</Text>)}
                    <Text as="span">{cbOrder.city}, {cbOrder.state} {cbOrder.postal_code}</Text>
                    <Text as="span">{cbOrder.country_code}</Text>
                    {cbOrder.phone && (<Text as="span">{cbOrder.phone}</Text>)}
                </Box>
                <OrderManagementLink receiptId={cbOrder.receipt_id} email={cbOrder.email} textTransform="uppercase" mt={4} fontStyle="regular" typeStyle="text03">
                    Edit contact information
                </OrderManagementLink>
            </>
        ) : (
            <>
                <LoadingSkeletonBox width="8em" />
                <LoadingSkeletonBox width="10em" mb={3} />
                <LoadingSkeletonBox width="10em" />
                <LoadingSkeletonBox width="10em" />
                <LoadingSkeletonBox width="5em" />
                <LoadingSkeletonBox width="7em" />
            </>
        )}
    </Box>
);

export default ShippingInformation;

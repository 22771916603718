import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import { ClickBankOrder } from "../../domain/@types";
import React from "react";
import PageThemeConfig from "../../domain/PageThemeConfig";
import Text from "@onnit-js/ui/components/text";
import OrderManagementLink from "../common/OrderManagementLink";

interface RefundBoxProps extends BoxProps {
    firstOrder: ClickBankOrder | null;
}

const RefundBox: React.FC<React.PropsWithChildren<React.PropsWithChildren<RefundBoxProps>>> = ({ firstOrder, ...rest }) => (
    <Box bg={PageThemeConfig.tanish} px={[4, 4, 4, 6]} mt={6} borderRadius="25px" mb={[4, 4, 6, 8]} pb={4} {...rest}>
        <Text typeStyle="title02" as="h2" pt={[4, 4, 6]}>Need a refund?</Text>
        <Text typeStyle="text03" fontWeight="regular" my={3} as="p">
            On the off chance it doesn't perform for you or you're not in love with our formula, we are happy to issue a refund for the cost of the product upon return within 90 days of the purchase
            date. We just ask that you try it out for at least two weeks to give it a fair shot.
        </Text>
        <Text typeStyle="text03" fontWeight="regular" as="p">
            To start the return process, please{" "}
            <OrderManagementLink receiptId={firstOrder?.receipt_id} email={firstOrder?.email} display="inline">
                notify our team
            </OrderManagementLink>{" "}
            and tell us why it wasn't a fit for you. To receive a refund, the remaining product must be returned within 90 days of the purchase date using a return shipping label sent to your email.
            Initial shipping fees are non-refundable and return shipping is $7, which will be deducted from the final refund.
        </Text>
    </Box>
);

export default RefundBox;

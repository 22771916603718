import React from "react";
import { PageProps } from "../../PageRouter";
import Box from "@onnit-js/ui/components/box/Box";
import Text, {Sup} from "@onnit-js/ui/components/text";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import PageThemeConfig from "../../domain/PageThemeConfig";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import Highlight from "../common/Highlight";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import TermsOfService from "./TermsOfService";
import approvedImages from "./approvedImages";
import {Img} from "@onnit-js/ui/components/image";

const AffiliatesPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<PageProps>>> = () => {
    useDocumentTitle('Onnit Affiliates');

    const onnitContext = useOnnitContext();
    if (!onnitContext) {
        return null;
    }

    const IMG_BASE_URL = onnitContext.url.cdn.static_images;
    const IMG_URL = `${IMG_BASE_URL}/landers/clickbank/affiliates/`;

    return (
        <>
            <Box maxWidth="1920px" mx="auto">
                <Box>
                    <Text as="h1" typeStyle="title01" fontSize={[6, 4, 6, 8]} textAlign="center" mt={8} px={4}>Onnit Affiliates</Text>
                    <TwelveColumn mt={[4, 4, 4, 6]} px={[4, 4, 4, 0]}>
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "2 / span 10"]}>
                            <Box px={[4, 4, 4, 4]}>
                                <Text typeStyle="text03" fontWeight="regular" as="p" py={3}>
                                    Once registered with ClickBank<Sup variant="reg" />{" "}you will be able to send traffic through your affiliate link, and you will automatically be credited 60%
                                    commission for all initial Onnit Alpha BRAIN<Sup variant="reg" />{" "}sales that result from traffic you send through your special ClickBank<Sup
                                    variant="reg" />{" "}affiliate link.
                                </Text>

                                <Text typeStyle="text03" fontWeight="regular" as="p" py={3}>
                                    In order to get approved to run the Alpha BRAIN<Sup variant="reg" />{" "} offer, please fill out our{" "}
                                    <Text as="a"
                                          target="_blank"
                                          href="https://docs.google.com/forms/d/e/1FAIpQLScf3qweFIemp85zHxVfjNpBWCbtBOxA8YFaU5biMI1NM7BDhQ/viewform?usp=sf_link"
                                          display="inline">short affiliate survey</Text>.
                                </Text>

                                <Text typeStyle="text03" fontWeight="regular" as="p" py={3}>
                                    If you have any questions, concerns, or need assistance or tools for your promotions, please contact our{" "}
                                    <Text as="a" href="mailto:affiliates@onnit.com" display="inline">Affiliates Team</Text>{" "}(affiliates@onnit.com).
                                </Text>
                            </Box>

                            <Box bg={PageThemeConfig.goldish} px={[4, 4, 4, 8]} mt={5} borderRadius="25px" mb={[4, 4, 6]} pt={2} pb={4}>
                                <Text typeStyle="title02" fontStyle="normal" as="h2" pt={[4, 4, 6]}>Email Swipes</Text>
                                <Text typeStyle="text03" fontWeight="regular">
                                    <Text typeStyle="text03" fontWeight="regular" as="p" py={3}>
                                        Access all of our pre-approved, high converting affiliate{" "}
                                        <Text typeStyle="text03"
                                              fontWeight="regular"
                                              as="a"
                                              display="inline"
                                              target="_blank"
                                              href="https://2252779.fs1.hubspotusercontent-na1.net/hubfs/2252779/Clickbank/Alpha_BRAIN_Emails_Swipes.odt">
                                            email swipes
                                        </Text>.<br />Use Google Docs, OpenOffice Writer, Word, or LibreOffice to open the .odt (Open Document Format)
                                    </Text>
                                </Text>
                            </Box>

                            <Box bg={PageThemeConfig.tanish} px={[4, 4, 4, 8]} mt={4} borderRadius="25px" mb={[4, 4, 6]} pt={2} pb={4}>
                                <Text typeStyle="title02" fontStyle="normal" as="h2" pt={[4, 4, 6]}>Product Images</Text>
                                <Text typeStyle="text03" fontWeight="regular" mb={2} as="p" py={3}>
                                    Here are images you can use in your promotions. Do not use any images from our website aside from the ones below. Click to open the image in a new window,
                                    then right-click "Save Image As" to save to your computer or{" "}
                                    <Text as="a"
                                          display="inline"
                                          target="_blank"
                                          href="https://scdn.onnit.com/images/landers/clickbank/affiliates/affiliate-images.zip">download</Text>{" "}all
                                    images at once.
                                </Text>
                                <Box display="grid"
                                     gridGap={[4, 4, 6]}
                                     gridTemplateColumns={[
                                         "repeat(auto-fit, minmax(150px, 1fr))",
                                         "repeat(auto-fit, minmax(200px, 1fr))",
                                         "repeat(auto-fit, minmax(300px, 1fr))",
                                         "repeat(auto-fit, minmax(300px, 1fr))",
                                         "repeat(auto-fit, minmax(300px, 1fr))",
                                     ]}>
                                    {approvedImages.map((image, index) => (
                                        <Box textAlign="center" mx="auto" key={index}>
                                            <Box
                                                mx="auto"
                                                bg={PageThemeConfig.goldish}
                                                borderRadius="10px"
                                                border={`2px solid ${PageThemeConfig.blackish}`}
                                                width={[150, 200, 325, 250, 300]}
                                                height={[150, 200, 325, 250, 300]}
                                                position="relative"
                                                zIndex={999}
                                                overflow="hidden"
                                            >
                                                <Box position="relative" zIndex={1} mx="auto" textAlign="center">
                                                    <Text as="a" display="block" zIndex={1} href={`${IMG_URL}${image.filename}`} target="_blank" textAlign="center" mx="auto">
                                                        <Box display="grid" justifyContent="center" alignItems="center" justifyItems="center">
                                                            <Img style={{ width: "auto !important" }} aria-hidden="true" src={`${IMG_URL}${image.filename}?&fm=png&fit=fill&w=300&h=300`} />
                                                        </Box>
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Text typeStyle="text04" fontWeight="regular" as="span">{image.description}</Text>
                                        </Box>
                                    ))}
                                </Box>
                                <Text typeStyle="text03" fontWeight="regular" mt={2} py={3}>
                                    <Highlight typeStyle="text03" fontWeight="heavy">NOTE:</Highlight> Affiliate advertising must strictly adhere to all legal requirements. All promotional
                                    content must abstain from
                                    any form of deception, misleading information, untruthfulness, or lack of substantiation, and must be in compliance with relevant federal and state consumer
                                    protection laws, regulations, and guidelines.<br />

                                    <Highlight typeStyle="text03" fontWeight="heavy">Non-compliance with these terms will result in an immediate and permanent ban of
                                        the affiliate advertiser,
                                        rendering them ineligible for reinstatement.</Highlight>
                                </Text>
                            </Box>
                        </Box>

                    </TwelveColumn>
                </Box>
            </Box>
            <Box bg={PageThemeConfig.creamWhite} mt={[6, 6, 8]}>
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 11", "2 / span 10"]} pb={8}>
                        <TermsOfService />
                    </Box>
                </TwelveColumn>
            </Box>
        </>
    );
};

export default AffiliatesPage;

import React, { FormEventHandler, ReactNode, useState } from "react";
import TextField from "@onnit-js/ui/components/form/textfield/TextField";
import Text from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import { EmailLeadSourceEnum, EmailListEnum } from "@onnit-js/ui/@types/enums/EmailList";
import { isValidEmail } from "../../lib/utils";
import MessageBox, {MessageLevelEnum} from "@onnit-js/ui/components/notification/MessageBox";
import Box from "@onnit-js/ui/components/box/Box";
import {HubSpotListSignupContactProperties} from "@onnit-js/ui/@types";

interface Props {
    emailInitial?: string;
    lists?: EmailListEnum[];
    leadSource?: EmailLeadSourceEnum;
    hubspotContactProperties?: HubSpotListSignupContactProperties; // Properties to set on the contact record in HubSpot.
    formTop?: ReactNode;
    onSuccess?: (email: string) => void;
    successMessage?: string;
}

const ERROR_GENERAL = "An error occurred. Please refresh your browser or try again later.";

const EmailListSignUpCustom: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    emailInitial = '',
    lists = [EmailListEnum.ONNIT_NEWSLETTER],
    leadSource = EmailLeadSourceEnum.ONNIT_NEWSLETTER,
    hubspotContactProperties,
    formTop,
    successMessage = 'Thanks for subscribing!',
    ...rest
}) => {
    const [email, setEmail] = useState<string>(emailInitial);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        setSuccessful(false);
        setError(null);

        if (!isValidEmail(email)) {
            setError("Please enter a valid email.");
            return;
        }
        if (!window?.ONNIT?.hubspot) {
            console.error("window.ONNIT.hubspot is undefined.");
            setError(ERROR_GENERAL);
            return;
        }

        const subscribed = window.ONNIT.hubspot.emailListSignup(email, lists, leadSource, hubspotContactProperties);
        if (!subscribed) {
            console.error("emailListSignup returned an erroneous result.");
            setError(ERROR_GENERAL);
            return;
        }

        onSuccess();
    };

    const onSuccess = () => {
        setSuccessful(true);
        setError(null);
        setEmail('');
        rest.onSuccess?.(email);
    };

    return (
        <form onChange={() => setSuccessful(false)} onSubmit={onSubmit}>
            {successful && (
                <MessageBox level={MessageLevelEnum.SUCCESS} message={successMessage} />
            )}
            {error && (
                <MessageBox level={MessageLevelEnum.ERROR} message={error} />
            )}
            {formTop}
            <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
            />
            <Box>
                <Text
                    mt={4}
                    color="grays.5"
                    typeStyle="caption02"
                >
                    Yes, I want to receive offers and updates
                    from Onnit and other related brands via email and online
                    marketing. I have read and acknowledge Onnit's{" "}
                    <Text as="a" display="inline" href="/privacy-policy/" target="_blank">Privacy Policy</Text> and{" "}
                    <Text as="a" display="inline" href="/financial-incentives-notice/" target="_blank">Notice of Financial Incentive</Text>.
                    I understand I may unsubscribe from promotional emails at any time.*
                </Text>
                <Text
                    mt={2}
                    color="grays.5"
                    typeStyle="caption02"
                    fontStyle="italic"
                    style={{
                        fontSize: "12px"
                    }}
                >
                    *If you unsubscribe, you may still receive non-marketing or transactional messages from us.
                </Text>
            </Box>

            <Box textAlign="center" pt={4}>
                <Button type="submit" width={1}>
                    Sign up now
                </Button>
            </Box>
        </form>
    );
};

export default EmailListSignUpCustom;

import { parse, stringify } from "qs";

import { ItemSku, UpsellFlowId } from "./@types";

enum ParamName {
    SKUS = 'cbitems',
    SESSION_ID = 'cbf',
    UPSELL_FLOW_ID = 'cbfid',
    UPSELL_ID = 'custom__upsell_id',
    CHECKOUT_SKIN_ID = 'cbskin',
}

export default class ClickBankUrlManager {
    private readonly GATEWAY_PAY_URL: string = 'https://onnit.pay.clickbank.net';
    private readonly CHECKOUT_SKIN_ID: number = 43249;
    public readonly FATAL_ERROR_REDIRECT_URI: string = '/cbk/alpha-brain';

    getAddToCartUrl(sku: ItemSku, upsellFlowId?: UpsellFlowId): string {
        const params = {
            [ParamName.SKUS]: sku,
            [ParamName.UPSELL_FLOW_ID]: upsellFlowId,
            [ParamName.CHECKOUT_SKIN_ID]: this.CHECKOUT_SKIN_ID,
        };
        return `${this.GATEWAY_PAY_URL}/?${stringify(params, { skipNulls: true })}`;
    }

    getUpsellDecisionUrls(sku: ItemSku, upsellId: string, sessionId?: string): [string | null, string] // [acceptUrl, declineUrl]
    {
        const params = {
            [ParamName.SESSION_ID]: sessionId,
            [ParamName.SKUS]: sku,
            [ParamName.UPSELL_ID]: upsellId,
        };
        const toUrl = (cbur: string) => `${this.GATEWAY_PAY_URL}/?${stringify({ ...params, cbur }, { skipNulls: true })}`;

        return [
            // Require a session ID for the accept URL to prevent unauthorized add-to-cart requests.
            sessionId ? toUrl('a') : null,
            toUrl('d'),
        ];
    }

    getEncryptedUrlParams(): [string, string] // [params, iv]
    {
        const parsed = parse(location.search, { ignoreQueryPrefix: true });
        const { params, iv } = parsed;
        if (!params || !iv) {
            throw new Error("Params and/or IV is not provided.");
        }
        if (typeof params !== 'string' || typeof iv !== 'string') {
            throw new Error("Params and/or IV is malformed.");
        }
        return [params, iv];
    }
}

import Text from "@onnit-js/ui/components/text/Text";
import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {Sup} from "@onnit-js/ui/components/text";
import PageThemeConfig from "../../domain/PageThemeConfig";
import Highlight from "../common/Highlight";

interface DisclaimerProps extends BoxProps {
    as?: string;
    textColor?: string;
    receiptId?: string;
    email?: string;
}

const TermsOfService: React.FC<React.PropsWithChildren<React.PropsWithChildren<DisclaimerProps>>> = ({ as = "h2", textColor = PageThemeConfig.darkGrayish, receiptId, email, ...rest }) => (
    <Box {...rest}>
        <Text typeStyle="title02" color={textColor} fontStyle="normal" as="h2" pt={[4, 4, 6, 8]} textAlign="center">Terms of Service</Text>
        <Box px={[4, 4, 0]} mx="auto" mt={[4, 4, 6]}>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                We're excited to partner with you on promoting Alpha BRAIN<Sup variant="reg" />{" "}by Onnit. Please review the following terms of service prior to sending traffic.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                <Highlight typeStyle="text04" color={textColor} fontWeight="heavy">NOTICE:</Highlight>{" "}All affiliates (regardless of their country of domicile) MUST be in compliance with all
                applicable laws,
                regulations, and guidelines, including without limitation the Federal
                Trade Commission Act ("FTC Act"), the Controlling the Assault of Non-Solicited Pornography and Marketing Act of 2003 ("CAN-SPAM Act"), Federal Trade Commission ("FTC") regulations and
                guidelines implementing the FTC Act and the CAN-SPAM Act, the FTC's Guides Concerning the Use of Endorsements and Testimonials in Advertising ("FTC Testimonials Guide") and the
                National Advertising Division decisions of the Better Business Bureau.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Affiliates are forbidden to run ads on Google Adwords (or any Google property) under any and all circumstances. Google is not allowed regardless of intended use of specific keywords.
                NO GOOGLE.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Affiliates running PPC campaigns on other networks are prohibited from bidding on "Alpha BRAIN" or other variations of this registered mark of Onnit. In other words, your keywords can
                not include the words "AlphaBRAIN", "Alpha BRAIN" or "Onnit" alone or in any combination of other words.
                Affiliates must also NEVER use any images or videos that they do not own and are forbidden from using any imagery that would imply any personal endorsement (celebrity or otherwise)
                without written consent from both the individuals making the endorsement AND Onnit.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Affiliates may not bypass Alpha BRAIN pages to generate orders. In other words, no direct linking to cart or order forms from any ads or landing pages. The customer must see our offer
                as presented by us prior to landing on the cart.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Affiliate agrees to indemnify, defend and hold harmless Onnit from any lawsuits, investigations, claims, or complaints arising from any such violation or alleged violation of the terms
                above. Compliance is solely the responsibility of the affiliate and the affiliate represents and warrants that she/he/it shall have legal review of all Affiliate Ads for all necessary
                and required compliance. Affiliates take full responsibility for their advertising.
            </Text>
            <Text typeStyle="text04" fontWeight="black" color={textColor} as="p" py={3}>
                Any affiliate that is found to have violated any of these terms and conditions will be banned immediately and will not be eligible for reinstatement.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                If you have any questions or concerns, or need assistance or tools for your promotions, please contact our{" "}
                <Text as="a" href="mailto:affiliates@onnit.com" display="inline">Affiliates Team</Text>.
            </Text>
        </Box>
    </Box>
);

export default TermsOfService;

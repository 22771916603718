import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import { ClickBankOrder } from "../../domain/@types";
import Text from "@onnit-js/ui/components/text";
import { MoneyFormat } from "../../../../lib/utils";
import LoadingSkeletonBox from "../common/LoadingSkeletonBox";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import Highlight from "../common/Highlight";


const Table: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = ({ children, ...rest }) => (
    <Box display="table" {...rest}>
        {children}
    </Box>
);

const TableRow: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = ({ children, ...rest }) => (
    <Box display="table-row" width="100%" {...rest}>
        {children}
    </Box>
);

const TableCell: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = ({ children, ...rest }) => (
    <Box style={{ float: "left" }} {...rest}>
        {children}
    </Box>
);

interface Props extends BoxProps {
    cbOrder?: ClickBankOrder; // `order` property already exists in BoxProps.
}

const Order: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ cbOrder, ...boxProps }) => {
    const totals = cbOrder?.totals;

    return (
        <Box {...boxProps}>
            <TwelveColumn px={4} py={6}>
                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "1 / span 5", "1 / span 5"]}>
                    {cbOrder ? (
                        <>
                            <Text as="h3" typeStyle="text02" fontSize={[2, 2, 1]} fontWeight="heavy" textTransform="uppercase" mb={[4, 4, 2]}>
                                Order #{cbOrder.receipt_id}
                            </Text>
                            {cbOrder.items.map(item => (
                                <Text as="span" key={item.sku} textTransform="uppercase" mb={3}>
                                    {item.name}
                                </Text>
                            ))}
                        </>
                    ) : (
                        <>
                            <LoadingSkeletonBox width="10em" mb={[4, 4, 2]} />
                            <LoadingSkeletonBox width="22em" />
                            <LoadingSkeletonBox width="18em" />
                        </>
                    )}
                </Box>
                <Box gridColumn={["1 / -1", "1 / -1", "8 / span 2", "9 / span 2", "10 / span 2"]} mt={[4, 4, 0]}>
                    {cbOrder && totals ? (
                        <Table width="300px">
                            <TableRow>
                                <TableCell width="40%" textAlign={["left", "left", "right"]}>
                                    <Highlight fontWeight="heavy" fontSize={[2, 2, 1]} textAlign="right">Subtotal:</Highlight>
                                </TableCell>
                                <TableCell width="60%">
                                    <Text as="span" pl={4}>{MoneyFormat.format(totals.item_total)}</Text>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="40%" textAlign={["left", "left", "right"]}>
                                    <Highlight fontWeight="heavy" fontSize={[2, 2, 1]} textAlign="right">Shipping:</Highlight>
                                </TableCell>
                                <TableCell width="60%">
                                    <Text as="span" pl={4}>{MoneyFormat.format(totals.shipping_total)}</Text>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="40%" textAlign={["left", "left", "right"]}>
                                    <Highlight fontWeight="heavy" fontSize={[2, 2, 1]} textAlign="right">Tax:</Highlight>
                                </TableCell>
                                <TableCell width="60%">
                                    <Text as="span" pl={4}>{MoneyFormat.format(totals.tax_total)}</Text>
                                </TableCell>
                            </TableRow>
                            <TableRow mt={2}>
                                <TableCell width="40%" textAlign={["left", "left", "right"]}>
                                    <Highlight as="span" fontWeight="heavy" fontSize={[2, 2, 1]} textAlign="right">
                                        Total:
                                    </Highlight>
                                </TableCell>
                                <TableCell width="60%">
                                    <Box as="span">
                                        <Text as="span" pl={4} fontWeight="heavy" display="inline">
                                            {MoneyFormat.format(totals.grand_total)}
                                        </Text>
                                        <Text as="span" display="inline" ml={1} fontSize={[1, 1, 1, 2]} color="grays.5">
                                            {cbOrder.currency_code}
                                        </Text>
                                    </Box>
                                    <Text as="span" pl={4} mt={1}>
                                        {cbOrder.payment_method}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </Table>
                    ) : (
                        <>
                            <LoadingSkeletonBox width="60%" mx="auto" />
                            <LoadingSkeletonBox width="60%" mx="auto" />
                            <LoadingSkeletonBox width="60%" mx="auto" />
                            <LoadingSkeletonBox width="60%" mx="auto" />
                        </>
                    )}
                </Box>
            </TwelveColumn>
        </Box>
    );
};

export default Order;

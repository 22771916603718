import Text, {TextProps} from "@onnit-js/ui/components/text/Text";
import React from "react";
import PageThemeConfig from "../../../domain/PageThemeConfig";
import Box from "@onnit-js/ui/components/box/Box";

interface DisclaimerProps extends TextProps {
    bgColor?: string;
    textColor?: string;
}

const Disclaimer: React.FC<React.PropsWithChildren<React.PropsWithChildren<DisclaimerProps>>> = ({ bgColor = PageThemeConfig.blackish, textColor = PageThemeConfig.creamWhite, ...rest }) => (
    <Box bg={bgColor} {...rest}>
        <Box maxWidth={1040} mx="auto" px={4} py={1}>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} textAlign={["left", "left", "center"]} as="p">
                The product reviews reflect individual experiences, and as individuals vary, so may results. To support and complement benefits, it is important to prioritize healthy
                lifestyle choices, including consistent exercise, a balanced diet, ample rest, and mindfulness. Use as directed.
            </Text>
        </Box>
    </Box>
);

export default Disclaimer;

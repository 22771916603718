import Text from "@onnit-js/ui/components/text/Text";
import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {Sup} from "@onnit-js/ui/components/text";
import OrderManagementLink from "./OrderManagementLink";
import PageThemeConfig from "../../domain/PageThemeConfig";

interface DisclaimerProps extends BoxProps {
    as?: string;
    textColor?: string;
    receiptId?: string;
    email?: string;
}

const FdaStatement: React.FC<React.PropsWithChildren<React.PropsWithChildren<DisclaimerProps>>> = ({ as = "h2", textColor = PageThemeConfig.darkGrayish, receiptId, email, ...rest }) => (
    <Box {...rest}>
        <Text as={as} className="sr-only">Statements and Disclaimers</Text>
        <Box mx="auto">
            <Text typeStyle="text04" fontWeight="bold" color={textColor} as="p" py={3}>
                <Sup variant="dagger" />{" "}THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION. THIS PRODUCT IS NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY
                DISEASE.<br />
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                ClickBank is the retailer of this product. CLICKBANK<Sup variant="reg" />{" "}is a registered trademark of Click Sales, Inc., a Delaware corporation located at 1444 S.
                Entertainment
                Ave., Suite 410 Boise, ID 83709, USA and used by permission. ClickBank’s role as retailer does not constitute an endorsement, approval, or review of this product or any claim,
                statement or opinion used in promotion of this product.<br />
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Statements on this website have not been evaluated by the Food and Drug Administration. Products are not intended to diagnose, treat, cure or prevent any disease. If you are
                pregnant,
                nursing, taking medication, or have a medical condition, consult your physician before using our products.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                Testimonials, case studies, and examples found on this page are results that have been forwarded to us by users of Onnit products, and related products are not intended to
                represent or
                guarantee that anyone will achieve the same or similar results.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} as="p" py={3}>
                For product support, please{" "}
                <Text as="a" display="inline" href="https://www.onnit.com/help/" target="_blank">
                    contact Onnit
                </Text>.
            </Text>
            <Text typeStyle="text04" fontWeight="regular" color={textColor} textAlign="left">
                For order support, please{" "}
                <OrderManagementLink display="inline" {...{ receiptId, email }}>
                    contact ClickBank
                </OrderManagementLink>.
            </Text>
        </Box>
    </Box>
);

export default FdaStatement;
